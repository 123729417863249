import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import styles from "./ContactUs.module.scss"
import "../App.scss"
import { SocialIcon } from "react-social-icons";
import downloadImage from "../assets/newground.svg";
import logoOne from '../assets/cremLogo.svg'
import xyz from '../assets/yjc.svg'
import aws from '../assets/Aws.svg'
import Logo from "../assets/logo.png"
const ball = require("../assets/ball.png");
const tiktok = require("../assets/tiktok.png")
const appBtn = require("../assets/appStoreButton.png");
const googleBtn = require("../assets/googlePlayStore.png");
const ContactUs = ({ setActive }) => {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    message: ""
  })
  const [loading, setloading] = useState(false);
  // for

  const onChangeState = (key, value) => {
    setUserInfo((prev) => ({ ...prev, [key]: value }));
  }

  const isValidated = () => {
    if (!userInfo.firstName) {
      toast.error("Please enter a name", { position: 'top-center' });
      return false;
    } else if (!userInfo.lastName) {
      toast.error("Please enter a name", { position: 'top-center' });
      return false;
    } else if (!userInfo.email) {
      toast.error("Please enter a email", { position: 'top-center' });
      return false;
    } else if (!userInfo.mobileNumber) {
      toast.error("Please enter a name", { position: 'top-center' });
      return false;
    } else if (!userInfo.message) {
      toast.error("Please enter a message", { position: 'top-center' });
    }
    return true;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidated()) {
      try {
        setloading(true);
        const response = await axios.post(
          "https://backend-email.cricketrplus.com/contactus",
          userInfo
        );
        toast.success(response?.data?.message, { position: 'top-center' })
        setActive(0)
        setUserInfo({})
        setloading(false);
      } catch (err) {
        setActive(0)
        const error = err?.response?.data?.message
        setloading(false);
        toast.error(error ? error : "Something went wrong.Please try again.", { position: 'top-center' });
      }

    }
  }
  return (
    <div className={styles.wrapper}>
      <div className="mainHeadSection w-100 h-100">
        <img
          src={ball}
          alt="ball"
          className="ballOne"

        />
        <img src={ball} alt="ball" className="ballTwo" />
        <div className="IconList">
          <ul className="ps-0">
            <li>
              <SocialIcon
                network="facebook"
                bgColor="transparent"
                fgColor="#ffffff"
                url="https://www.facebook.com/cricketrplus"
              />
            </li>
            <li>
              <SocialIcon
                network="linkedin"
                bgColor="transparent"
                fgColor="#ffffff"
                url="#"
              />
            </li>
            <li>
              <SocialIcon
                network="instagram"
                bgColor="transparent"
                fgColor="#ffffff"
                url="https://www.instagram.com/cricketr_plus/"
              />
            </li>
            <li>
              <SocialIcon
                network="twitter"
                bgColor="transparent"
                fgColor="#ffffff"
                url="https://twitter.com/CricketRplus"
              />
            </li>
            <li>
              <a href="https://www.tiktok.com/@cricketrplus">
                <img src={tiktok} alt="tiktok" />
              </a>
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5">
              <div className="mainHeading text-center text-white pt-1">
                <h2 className="mb-0">
                  <img src={Logo} alt="" />
                </h2>
              </div>
              <div className="mainHeading text-center text-white pt-1" style={{ marginTop: "100px" }}>
                <h1 className="mb-0">
                  Contact us
                </h1>
              </div>
              <p className="py-4 mb-0 text-white text-center">
                Our friendly team would love to hear from you!
              </p>

              <div className="socialBtns display-flex text-center flex-wrap pt-3 pb-3">

                <img src={googleBtn} alt="social-btn" className="m-2" />
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 mx-auto" id="Register">
        <form

          onSubmit={(e) => handleSubmit(e)}
        >
          <div class="mb-4 mt-4 ContactUsFields">
            <input
              type="text"
              className="form-control py-3"
              required
              id="First Name"
              placeholder="First Name"
              value={userInfo.firstName}
              onChange={(e) => onChangeState("firstName", e.target.value)}
              name="firstName"
            />
            <input
              type="text"
              className="form-control py-3"
              required
              id="User Name"
              placeholder="Last Name"
              value={userInfo.lastName}
              onChange={(e) => onChangeState("lastName", e.target.value)}
              name="lastName"
            />
          </div>
          <div class="mb-4 mt-4 ContactUsFields">
            <input
              type="email"
              className="form-control py-3"
              id="email"
              value={userInfo.email}
              placeholder="Enter your email"
              onChange={(e) => onChangeState("email", e.target.value)}
              name="email"
              required
            />
            <input
              type="number"
              className="form-control py-3"
              required
              id="User Name"
              placeholder="Mobile Number"
              value={userInfo.mobileNumber}
              onChange={(e) => onChangeState("mobileNumber", e.target.value)}
              name="mobileNumber"
            />
          </div>
          <div class="mb-4 mt-4">
            <textarea
              type="text"
              className="form-control py-3"
              required
              id="User Name"
              placeholder="Message..."
              value={userInfo.message}
              onChange={(e) => onChangeState("message", e.target.value)}
              name="message"
            />
          </div>
          <button
            disabled={loading}
            type="submit"
            class="btn py-2 w-100 text-white"
          >
            {loading ? "Loading..." :
              "Submit"}
          </button>
        </form>
      </div>
      <div className="downloadSection w-100">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="downloadImageCol p-4">
                <img src={downloadImage} alt="download" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 d-flex align-items-center">
              <div className="downloadContentCol p-4">
                <p className="text-white mb-0">Download</p>
                <p>CricketR+ App</p>
                <p className="text-white">
                  Enter the exciting world of CricketR, a real time scoring and
                  cricketing world ecosystem. Players get to create their own
                  virtual CricketR Card, and record + broadcast live personal,
                  local professional and international cricket matches.
                </p>
                <div className="socialBtns display-flex flex-wrap pt-3 pb-3">
                  <img src={appBtn} alt="social-btn" className="m-2 ms-0" />
                  <img src={googleBtn} alt="social-btn" className="m-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-2">
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center py-2">
              <div className="footersection w-100 text-center d-flex flex-wrap justify-content-center align-items-center">
                <p className="text-white pe-3 py-2">Powered By</p>
                <div className="footerImages d-flex  my-2">
                  <img src={xyz} className="pe-4 mb-2" alt="aws" />
                  <img src={logoOne} alt="aws" className="mb-2" />
                  <img src={aws} className="ps-4 pt-1" atl="logo" />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 text-center py-2">
              <p className="text-white mb-0">919 North Market Street, Suite 950, Wilmington, Delaware</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 text-center">
              <p className="text-white mb-0">All Rights Reserved by <label>CricketR. Plus Inc &copy;</label></p>
            </div>
            <div className="col-lg-6 col-md-6 text-center" onClick={() => setActive(1)}>
              <p className="text-white mb-0">Contact Us</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs