

import { BrowserRouter as Router, Route, Link, BrowserRouter, Routes, useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import "./App.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import { SocialIcon } from "react-social-icons";
import logoOne from './assets/cremLogo.svg'
import xyz from './assets/yjc.svg'
import aws from './assets/Aws.svg'
import commingSoonImage from "./assets/comingsoon.svg"
import footerGoogleLogo from "./assets/footergoogleplay.svg"
import ImagegooglePlay from "./assets/Google Play logo.svg"
import ImageApplePlay from "./assets/Apple logo.svg"
import appStoreImage from "./assets/appStore.svg"
import googleStoreImage from "./assets/googleplay.svg"
import VectorImage from "./assets/Vector.svg"
import HornImage from "./assets/horn.svg"
import TimeImage from "./assets/time.svg"
import ProfileImage from "./assets/profile.svg"
import NoteBookImage from "./assets/notepad.svg"
import NftImage from "./assets/nft.svg"
import BatImage from "./assets/bat.svg"
import CalanderImage from "./assets/calendar.svg"
import { CountryDropdown } from "react-country-region-selector";
import PrivacyAndPolicy from "./privacy.js";
import Home from "./home.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import downloadImage from "./assets/newground.svg";
import BannerImages from "./assets/rrrrr.svg"
import mobileRow from "./assets/landing page new.svg";
import mainImage from "./assets/multiplepic.svg";
import Odometer from "odometer";
import ContactUs from "./ContactUS";
const logo = require("./assets/logo.png");
const appBtn = require("./assets/appStoreButton.png");
const googleBtn = require("./assets/googlePlayStore.png");
const bannerImage = require("./assets/bannerImage.png");

const tiktok = require("./assets/tiktok.png")
const ball = require("./assets/ball.png");
const redRectangle = require("./assets/Rectangle-red.png");

const thirdLogo = require("./assets/yjc.svg")
const TRACKIND_ID = "UA-258385210-1";
ReactGA.initialize(TRACKIND_ID);

function App() {

  return (

    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
