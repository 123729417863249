import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from './Modal'
import "./Privacy.css"
const PrivacyAndPolicy = ({ togglepopup, setpopupvisible, popupvisible, setAgree, }) => {

  const [terms, setTerms] = useState(null)

  const handleGetValue = (event) => {
    if (event) {
      setTerms(true)

    } else {
      setTerms(false)
    }

  }

  const submitValue = () => {
    if (terms) {
      setAgree(true)
      setpopupvisible(false)
      toast.success("You have agreed the terms and conditions", { position: 'top-left' })
    } else {
      toast.error("Please read terms and conditions", { position: 'top-left' })
      alert("Please agreed the terms and conditions")
      setAgree(false)
      return;
    }
  }
  return (
    <div className="w-1/2">
      <div class="main-section">
        <h1>Privacy Policy for YJVC</h1>
        <p>
          At CricketR, accessible from yjvc.com, one of our main priorities is the
          privacy of our visitors. This Privacy Policy document contains types of
          information that is collected and recorded by CricketR and how we use
          it.
        </p>
        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>
        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in CricketR. This policy is not applicable to any
          information collected offline or via channels other than this website.
        </p>
        <br />
        <h2>Consent</h2>
        <p>
          By using our website, you hereby consent to our Privacy Policy and agree
          to its terms.
        </p>
        <br />
        <h2>Information we collect</h2>
        <p>
          The personal information that you are asked to provide, and the reasons
          why you are asked to provide it, will be made clear to you at the point
          we ask you to provide your personal information.
        </p>
        <p>
          If you contact us directly, we may receive additional information about
          you such as your name, email address, phone number, the contents of the
          message and/or attachments you may send us, and any other information
          you may choose to provide.
        </p>
        <p>
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address, email
          address, and telephone number.
        </p>
        <br />
        <h2>How we use your information</h2>
        <p>We use the information we collect in various ways, including to:</p>
        <ul>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our partners,
            including for customer service, to provide you with updates and other
            information relating to the website, and for marketing and promotional
            purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>
        <br />
        <h2>Log Files</h2>
        <p>
          CricketR follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this and
          a part of hosting services' analytics. The information collected by log
          files include internet protocol (IP) addresses, browser type, Internet
          Service Provider (ISP), date and time stamp, referring/exit pages, and
          possibly the number of clicks. These are not linked to any information
          that is personally identifiable. The purpose of the information is for
          analyzing trends, administering the site, tracking users' movement on
          the website, and gathering demographic information.
        </p>
        <br />
        <h2>Cookies and Web Beacons</h2>
        <p>
          Like any other website, CricketR uses 'cookies'. These cookies are used
          to store information including visitors' preferences, and the pages on
          the website that the visitor accessed or visited. The information is
          used to optimize the users' experience by customizing our web page
          content based on visitors' browser type and/or other information.
        </p>
        <br />
        <h2>Google DoubleClick DART Cookie</h2>
        <p>
          Google is one of a third-party vendor on our site. It also uses cookies,
          known as DART cookies, to serve ads to our site visitors based upon
          their visit to www.website.com and other sites on the internet. However,
          visitors may choose to decline the use of DART cookies by visiting the
          Google ad and content network Privacy Policy at the following URL –
          <a href="https://policies.google.com/technologies/ads"
          >https://policies.google.com/technologies/ads</a>
        </p>
        <br />
        <h2>Our Advertising Partners</h2>
        <p>
          Some of advertisers on our site may use cookies and web beacons. Our
          advertising partners are listed below. Each of our advertising partners
          has their own Privacy Policy for their policies on user data. For easier
          access, we hyperlinked to their Privacy Policies below.
        </p>
        <ul>
          <li>
            <p>Google</p>
            <p>
              <a href="https://policies.google.com/technologies/ads"
              >https://policies.google.com/technologies/ads</a>
            </p>
          </li>
        </ul>
        <br />
        <h2>Advertising Partners Privacy Policies</h2>
        <p>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of CricketR.
        </p>
        <p>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on CricketR, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>
        <p>
          Note that CricketR has no access to or control over these cookies that
          are used by third-party advertisers.
        </p>
        <br />
        <h2>Third Party Privacy Policies</h2>
        <p>
          CricketR's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed information.
          It may include their practices and instructions about how to opt-out of
          certain options.
        </p>
        <p>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management with
          specific web browsers, it can be found at the browsers' respective
          websites.
        </p>
        <br />
        <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
        <p>
          Under the CCPA, among other rights, California consumers have the right
          to:
        </p>
        <p>
          Request that a business that collects a consumer's personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </p>
        <p>
          Request that a business delete any personal data about the consumer that
          a business has collected.
        </p>
        <p>
          Request that a business that sells a consumer's personal data, not sell
          the consumer's personal data.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you would
          like to exercise any of these rights, please contact us.
        </p>
        <h2>GDPR Data Protection Rights</h2>
        <p>
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <p>
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </p>
        <p>
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </p>
        <p>
          The right to erasure – You have the right to request that we erase your
          personal data, under certain conditions.
        </p>
        <p>
          The right to restrict processing – You have the right to request that we
          restrict the processing of your personal data, under certain conditions.
        </p>
        <p>
          The right to object to processing – You have the right to object to our
          processing of your personal data, under certain conditions.
        </p>
        <p>
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you would
          like to exercise any of these rights, please contact us.
        </p>
        <h2>Children's Information</h2>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p>
          CricketR does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best efforts
          to promptly remove such information from our records.
        </p>

        <br />
        <h1>Terms and Conditions for YJVC</h1>
        <ol>
          <li>
            <h2>Introduction</h2>
            <p>
              These terms and conditions ("Terms") govern your access and use of
              the YJVC (yjvc.com) and the CricketR mobile application
              (collectively, the "Service"). By accessing or using the Service,
              you acknowledge that you have read, understood, and agree to be
              bound by these Terms. If you do not agree to these Terms, you are
              prohibited from accessing or using the Service.
            </p>
          </li>
          <li>
            <h2>User Agreement</h2>
            <ol type="a">
              <li>
                The Service is made available to you for your lawful, personal,
                and non-commercial use only.
              </li>
              <li>
                You are solely responsible for any content or information you make
                available through the Service, including without limitation, any
                consequences of uploading, posting, or transmitting such content
                or information.
              </li>
              <li>
                You agree not to use the Service to:
                <ol type="i">
                  <li>
                    Infringe upon any third-party rights, including but not
                    limited to intellectual property rights and privacy rights
                  </li>
                  <li>
                    Transmit any harmful, offensive, or otherwise inappropriate
                    content
                  </li>
                  <li>Engage in any illegal or fraudulent activities</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h2>Disclaimer of Warranties</h2>
            <ol type="a">
              <li>
                The Service is provided on an "as is" and "as available" basis.
                YJVC makes no representations or warranties of any kind, express
                or implied, as to the operation of the Service, the accuracy,
                completeness, or reliability of any content available through the
                Service, or that the Service will be uninterrupted or error-free.
              </li>
              <li>
                YJVC shall not be liable for any interruptions or errors in the
                Service, or for any damages arising from the use of the Service,
                including without limitation, direct, indirect, incidental, or
                consequential damages.
              </li>
            </ol>
          </li>
          <li>
            <h2>Limitation of Liability</h2>
            <ol type="a">
              <li>
                YJVC shall not be liable for any damages, including without
                limitation, direct, indirect, incidental, special, or
                consequential damages, arising from or in connection with your use
                of the Service. This includes, but is not limited to, damages for
                loss of profits, goodwill, use, data, or other intangible losses.
              </li>
            </ol>
          </li>
          <li>
            <h2>Changes to Terms and Service</h2>
            <p>
              YJVC reserves the right, in its sole discretion, to modify, add, or
              remove portions of these Terms at any time. We will notify you of
              any material changes to these Terms by posting the updated Terms on
              the Service. Your continued use of the Service after any changes to
              these Terms constitutes your acceptance of the updated Terms.
            </p>
          </li>
          <li>
            <h2>Governing Law and Dispute Resolution</h2>
            <p>
              These Terms shall be governed by and construed in accordance with
              the laws of the jurisdiction in which the Service is provided. Any
              dispute arising from these Terms shall be resolved through binding
              arbitration in accordance with the commercial arbitration rules of
              the jurisdiction in which the Service is provided.
            </p>
          </li>
          <li>
            <h2>General Provisions</h2>
            <ol type="a">
              <li>
                These Terms constitute the entire agreement between you and YJVC
                regarding the use of the Service.
              </li>
              <li>
                If any provision of these Terms is held to be invalid or
                unenforceable, such provision shall be struck and the remaining
                provisions shall be enforced.
              </li>
              <li>
                YJVC’s failure to enforce any right or provision in these Terms
                shall not constitute a waiver of such right or provision unless
                acknowledged and agreed to by us in writing.
              </li>
            </ol>
          </li>
        </ol>
        <br />

        <div name="myForm">
          <input type="checkbox" name="terms" id="checker" onChange={(e) => handleGetValue(e.target.value)} /> I agree to the Terms and Conditions
          <br />
          <div class="back-button" onClick={() => submitValue()}>
            <input type="submit" value="Submit" class="btn btn-primary" />
          </div>
          <br />
        </div>
      </div>
    </div>
  )
}

export default PrivacyAndPolicy