

import { BrowserRouter as Router, Route, Link, BrowserRouter, Routes, useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import "./App.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import { SocialIcon } from "react-social-icons";
import logoOne from './assets/cremLogo.svg'
import xyz from './assets/yjc.svg'
import aws from './assets/Aws.svg'
import commingSoonImage from "./assets/comingsoon.svg"
import footerGoogleLogo from "./assets/footergoogleplay.svg"
import ImagegooglePlay from "./assets/Google Play logo.svg"
import ImageApplePlay from "./assets/Apple logo.svg"
import appStoreImage from "./assets/appStore.svg"
import googleStoreImage from "./assets/googleplay.svg"
import VectorImage from "./assets/Vector.svg"
import HornImage from "./assets/horn.svg"
import TimeImage from "./assets/time.svg"
import ProfileImage from "./assets/profile.svg"
import NoteBookImage from "./assets/notepad.svg"
import NftImage from "./assets/nft.svg"
import BatImage from "./assets/bat.svg"
import CalanderImage from "./assets/calendar.svg"
import { CountryDropdown } from "react-country-region-selector";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import downloadImage from "./assets/newground.svg";
import BannerImages from "./assets/rrrrr.svg"
import mobileRow from "./assets/landing page new.svg";
import mainImage from "./assets/multiplepic.svg";
import Odometer from "odometer";
import ContactUs from "./ContactUS";
const logo = require("./assets/logo.png");
const appBtn = require("./assets/appStoreButton.png");
const googleBtn = require("./assets/googlePlayStore.png");
const bannerImage = require("./assets/bannerImage.png");

const tiktok = require("./assets/tiktok.png")
const ball = require("./assets/ball.png");
const redRectangle = require("./assets/Rectangle-red.png");

const thirdLogo = require("./assets/yjc.svg")
const TRACKIND_ID = "UA-258385210-1";
ReactGA.initialize(TRACKIND_ID);


const Home = () => {
    const [active, setActive] = useState(0)
    const [timerDay, setTimerDay] = useState();
    const [timerHours, setTimerHours] = useState();
    const [timerMinutes, setTimerMinutes] = useState();
    const [timerSeconds, setTimerSeconds] = useState();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [age, setAge] = useState();
    const [player, setPlayer] = useState("");
    const [experienceLevel, setExperienceLevel] = useState("");
    const [country, setCountry] = useState("");
    const [laoding, setLoading] = useState(false);
    const [userName, setUserName] = useState("");
    const [subscriber, setSubscriber] = useState("");
    const [ageError, setAgeError] = useState(false);
    const options = ["Male", "Female", "Other"];
    const playerType = ["Batsman", "Bowler", "Wicket Keeper", "Umpire"];
    const experience = ["Amateur", "Intermediate", "Expert", "Professional"];
    const [popupVisible, setPopupVisible] = useState(false);
    const [agree, setAgree] = useState(false)

    let interval;
    const setTimer = () => {
        const countDownDate = new Date("March 8,2023").getTime();
        interval = setInterval(() => {
            const now = new Date().getTime();

            const distance = countDownDate - now;

            const days = Math.floor(distance / (24 * 60 * 60 * 1000));
            const hours = Math.floor(
                (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
            const seconds = Math.floor((distance % (60 * 1000)) / 1000);

            // if(distance<0){
            //   clearInterval(interval.current);
            // }
            // else{
            setTimerDay(days);
            setTimerHours(hours);
            setTimerMinutes(minutes);
            setTimerSeconds(seconds);
            // }
        });
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(
                "https://backend-email.cricketrplus.com/totalUsers"
            );
            setSubscriber(response.data?.count);
            testingFunction(response.data?.count)
        } catch (e) {
            console.log("e", e);
        }
    };



    const onSubmit = async (e) => {

        try {
            e.preventDefault();

            if (age < 18 || age > 80) {
                setAgeError(true);
                return;
            }
            setLoading(true);
            console.log("gender", gender);
            const response = await axios.post(
                "https://backend-email.cricketrplus.com/subscribe",
                {
                    userEmail: email,
                    userName: name,
                    userCountry: country,
                    userGender: gender,
                    userPlayer: player,
                    userAge: age,
                    userExperience: experienceLevel,
                }
            );
            if (!agree) {
                e.preventDefault();
                setPopupVisible(true)
            }
            ReactGA.event({
                category: "form",
                action: "submit",
                label: "Pre register",
            });
            console.log("response", response);

            if (response.status === 200) {
                setUserName(response?.data?.data?.userName);
                fetchUsers();
                setLoading(false);
                setName(" ");
                setEmail(" ");
                setPlayer(" ");
                setCountry(" ");
                setGender(" ");
                setAge();
                setExperienceLevel(" ");
            }

        } catch (e) {
            console.log("e", e);
            setLoading(false);
            setName("");
            setAge();
            setEmail("");
            setCountry("");
            setGender("");
            setPlayer("");
        }
    };

    const testingFunction = (element) => {
        var el = document.querySelector('.testing');
        let od = new Odometer({
            el: el,
            value: 0,
            auto: true, // Don't automatically initialize everything with class 'odometer'
            selector: 'testing', // Change the selector used to automatically find things to be animated
            format: 'd', // Change how digit groups are formatted, and how many digits are shown after the decimal point
            duration: 3000, // Change how long the javascript expects the CSS animation to take
            animation: 'count',// Count is a simpler animation method which just increments the value,
            theme: 'digital'
        });

        od.update(element)
        // or
        el.innerHTML = element
    }


    const togglePopup = (e, num) => {
        setPopupVisible((preview) => !preview);
    };
    useEffect(() => {
        setTimer();
        fetchUsers();

        setTimeout(() => { fetchUsers(); }, 5000)
    }, [subscriber]);

    const str = userName && userName.charAt(0);
    const str2 = userName && userName.slice(1);


    return (

        <div className="w-100 h-100">


            <div className="downloadSection w-100">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="downloadImageCol p-4">
                                <img src={downloadImage} alt="download" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 d-flex align-items-center">
                            <div className="downloadContentCol p-4">
                                <p className="text-white mb-0">Download</p>
                                <p>CricketR+ App</p>
                                <p className="text-white">
                                    Your go-to place for everything cricket. From live scores to tournaments, exclusive player cards to fantasy cricket, academy management to vendor management and more. Download now for an exhilarating cricket experience.
                                </p>
                                <div className="socialBtns display-flex flex-wrap pt-3 pb-3">
                                    <img src={appBtn} alt="social-btn" className="m-2 ms-0" />
                                    <a href="https://play.google.com/store/apps/details?id=com.cricketr.livegame&pli=1" target="_blank">


                                        <img src={googleBtn} alt="social-btn" className="m-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Third Section*/}

            <div className="footer-2">
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center py-2">
                            <div className="footersection w-100 text-center d-flex flex-wrap justify-content-center align-items-center">
                                <p className="text-white pe-3 py-2">Powered By</p>
                                <div className="footerImages d-flex  my-2">
                                    <img src={xyz} className="pe-4 mb-2" alt="aws" />
                                    <img src={logoOne} alt="aws" className="mb-2" />
                                    <img src={aws} className="ps-4 pt-1" atl="logo" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 text-center py-2">
                            <p className="text-white mb-0">919 North Market Street, Suite 950, Wilmington, Delaware</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer py-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 text-center">
                            <p className="text-white mb-0">All Rights Reserved by <label>CricketR. Plus Inc &copy;</label></p>
                        </div>
                        {/* <div className="col-lg-6 col-md-6 text-center" >
                            <p className="text-white mb-0 cursor-pointer">Contact Us</p>
                            <p className="text-white mb-0 cursor-pointer" >Contact Us</p>

                        </div> */}
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Home;